body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;

    --popup-background-color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;
}

h2 {
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
}

button {
    color: inherit;
}

input {
    font: inherit;
}

textarea {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

select {
    color: black;
    background-color: transparent;
    padding: 0;
    margin: 0;
    font: inherit;
}

option {
    padding: 0;
    margin: 0;
}

textarea::placeholder {
    color: rgba(0, 0, 0, 0.2);
}

option::placeholder {
    color: rgba(0, 0, 0, 0.2);
}

.split {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.large-button {
    background-color: #F2F2F3;
    color: black;
    padding: 12px;
    font-weight: bold;
    border-radius: 20px;
    border: 0;
    text-align: center;
    font-size: 16px;
    align-self: stretch;
}

.large-button:disabled {
    background-color: #AAAAAA;
    color: white;
}

.blue {
    background-color: #008DF2;
    color: white;
}

.red {
    background-color: #FB0F00;
    color: white;
}

.underline {
    text-decoration: underline;
}

.font-orange {
    color: #FF8A00;
}

.font-light {
    color: #A5A5A5;
}

.font-red {
    color: #FB0F00;
}

.font-blue {
    color: #008DF2;
}

.font-mid {
    color: #595959;
}

.font-xlarge {
    font-size: 2.0em;
}

.font-large {
    font-size: 1.0em;
}

.font-small {
    font-size: 0.9em;
}

.font-bold {
    font-weight: bold;
}

.default-list {
    list-style-type: disc;
    margin-left: 30px;
}

.speaking-bubble {
    position: relative;
    background: var(--popup-background-color);
    border-radius: 20px;
}

.speaking-bubble::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--popup-background-color);
    transform: translateX(-50%);
}

select {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.selection-buttons ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.selection-buttons button {
    margin: 10px 0;
    display: flex;
    justify-content: start;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 20px 15px;
    text-align: left;
    gap: 10px;
    width: 100%;
    align-items: center;
}

.selection-buttons button > div {
    flex-grow: 1;
}

.blocking-popup {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
}

.blocking-popup > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--popup-background-color);
    width: 80%;
    padding: 20px;
    border-radius: 20px;
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: white;
}

.loading img {
    position: absolute;
    max-width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#root {
    height: 100%;
    width: min(60vh, 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#live2d {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: -1;
}

#error {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 60%;
}

#error > button {
    margin-top: 10px;
    margin-bottom: 10px;
}

#sign-in-header {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
    height: 20px;
}

#sign-in-header .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#sign-in-options {
    margin: 20px;
    display: flex;
    flex-direction: column;
}

#sign-in-options li {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 15px 10px 15px;
    margin: 10px 0 10px 0;
    display: flex;
}

#sign-in-options li > button {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: inherit;
    font-size: 0.9em;
    font-weight: bold;
}

#sign-in-options li > button > p {
    flex-grow: 1;
}

#sign-up {
    margin: 20px;
}

#sign-up form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#sign-up .email {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

#first-sign-in {
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.vertical-form form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.vertical-form .item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
}

.vertical-form .select__value-container {
    padding: 0;
}

.vertical-form .select__control {
    border: 0;
    min-height: 0;
}

.vertical-form .select__indicator {
    padding-top: 0;
    padding-bottom: 0;
}

.vertical-form .select__single-value {
    margin-left: 0;
}

.vertical-form .select__placeholder {
    margin-left: 0;
}

.vertical-form .select__input-container {
    margin: 0;
    padding: 0;
}

.vertical-form input {
    border: 0;
    padding: 0;
    margin: 0;
}

#first-sign-in .error {
    color: red;
}

#first-sign-in select {
    display: block;
    border: 0;
}

#first-sign-in .consent {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

#first-sign-in .sign-out {
    margin: 30px;
    text-align: center;
}

#home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 5px;
}

#home-header > div {
    display: flex;
    gap: 10px;
    align-items: center;
}

#home {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

#home .main {
    flex-grow: 1;
    overflow-y: scroll;
}

#home .touch-home-popup {
    position: absolute;
    width: 80%;
    top: auto;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 15px 30px 15px 15px;
    font-weight: bold;
    --popup-background-color: #F2F2F3;
    border-radius: 10px;
}

#home .touch-home-popup span {
    color: #00C969;
}

#home .touch-home-popup .icon-wrapper {
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    border-radius: 10px;
    background: white;
}

#home .touch-home-popup .icon-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    padding: 10%;
    box-sizing: border-box;
}

#home .touch-home-popup .cancel {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    margin: 5px;
}

#bonus-coins-popup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    align-items: center;
}

.character-list-item {
    display: flex;
    text-align: left;
    margin: 15px;
    align-items: center;
}

.character-list-item .icon {
    width: min(35vw, 20vh);
    height: min(35vw, 20vh);
    min-width: min(35vw, 20vh);
    min-height: min(35vw, 20vh);
    border-radius: 40px;
    position: relative;
}

.character-list-item .character {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.character-list-item .character-type {
    position: absolute;
    top: 0;
    left: 0;
}

.character-list-item .caption {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    gap: 5px;
}

.character-list-item h2 {
    margin-top: 0;
    margin-bottom: 0;
}

#chat-header {
    position: absolute;
    padding: 20px;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#chat {
    display: flex;
    flex-direction: column;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
}

#chat-feed {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#chat-feed .test {
    height: 0;
    background: linear-gradient(red, yellow);
}

#chat-feed .bubble {
    border-radius: 20px;
    margin: 10px;
    padding: 20px;
    max-width: 70%;
    background: rgba(255, 255, 255, 0.9);
    font-weight: bold;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

#chat-feed .bubble img {
    border-radius: 100%;
    width: 30px;
    height: 30px;
}

#chat-feed .request {
    align-self: center;
    background: #00CF6Bf0;
    color: white;
}

#chat-feed .response {
    align-self: center;
    margin-top: 10vh;
}

#chat-feed .response > div {
    overflow-y: scroll;
    max-height: 100px;
}

#chat-tutorial {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    text-align: center;
}

.tutorial-bubble {
    padding: 20px;
    margin: 20px;
}

#chat-speech {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 50px;
}

#chat-speech .exit {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
}

#chat-speech > div {
    position: absolute;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-items: center;
    align-items: center;
}

#chat-speech .animation {
    width: 160px;
    height: 100px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

#chat-speech .animation-bar {
    width: 16px;
    background-color: #ffffff;
    border-radius: 8px;
}

#chat-speech .transcripts {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: white solid 1px;
    border-radius: 10px;
    padding: 20px;
    height: 100px;
    align-self: stretch;
    overflow-y: scroll;
}

#chat-form {
    background: #F2F2F3;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

#chat-form .main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 20px;
    gap: 20px;
}

#chat-form .default-buttons {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    gap: 10px;
    overflow-x: auto;
    white-space: nowrap;
}

#chat-form .default-buttons button {
    background: white;
    padding: 7px 14px;
    border-radius: 10px;
    flex-grow: 1;
    font-weight: bold;
    font-size: 0.9em;
}

#chat-form .main-buttons {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    gap: 10px;
}

#chat-form .main-buttons button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1;
    font-size: 0.9em;
}

#chat-form .keyboard {
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 20px;
    align-items: center;
}

#chat-form .keyboard .input-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#chat-form .keyboard input {
    flex-grow: 1;
    border: 0;
    border-bottom: 1px solid #008DF2;
    border-radius: 0;
    background: transparent;
}

#chat-form .keyboard input:focus {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#chat-form .keyboard img {
    height: 30px;
    width: 30px;
}

#chat-end-page {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    display: flex;
    flex-direction: column;
    background: white;
    gap: 15px;
    text-align: center;
    align-items: stretch;
}

#chat-end-page .main {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

#chat-end-page .main img {
    border-radius: 100%;
    width: min(40vw, 20vh);
}

#chat-end-page .main a {
    align-self: stretch;
}

#purchase-popup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    align-items: center;
}

#purchase-popup h2 {
    margin: 0;
}

#purchase-popup button {
    align-self: stretch;
}

#purchase-popup a {
    align-self: stretch;
}

#profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 30px;
}

#profile h2 {
    margin: 0;
}

#profile .pic {
    border-radius: 100%;
    width: min(20vw, 10vh);
    height: min(20vw, 10vh);
}

#profile .coins {
    display: flex;
    align-items: center;
    gap: 5px;
}

#profile .coins > p {
    margin-top: 3px;
}

#profile .split {
    width: 100%;
    margin: 15px;
}

#profile .edit-name {
    display: flex;
    height: 40px;
    padding: 7px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: #F2F2F3;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: inherit;
}

#profile .purchase {
    background-color: #FF8A00;
    padding: 10px 30px;
    color: white;
    border-radius: 20px;
}

#profile-edit {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

#purchase {
    padding: 30px 20px;
}

#purchase > h2 {
    margin: 0;
    margin-bottom: 30px;
}

.purchase-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.purchase-item > div {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

#purchase .buttons {
    display: flex;
    flex-direction: column;
}

#purchase .policy-button {
    margin: 20px;
    text-align: center;
}

#purchase .policy-button a {
    color: #008DF2;
}

#purchase-result {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
}

#purchase-result > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

#menu li {
    padding: 15px;
}

#menu .button {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.deletion-popup {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    gap: 20px;
}

.deletion-popup > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.deletion-popup > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
}

#purchase-history {
    margin: 20px;
}

#purchase-history-feed {
    margin-top: 20px;
}

#purchase-history-feed li {
    padding: 10px 20px;
}

#purchase-history-feed li .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}

#purchase-history-feed li .main > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

#exit {
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#exit .item {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

#exit .textarea-item {
    display: flex;
    flex-direction: column;
}

#exit .textarea-item > p {
    text-align: end;
}

#exit-done {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#exit-done button {
    margin: 20px;
}

#admin-page {
    padding: 20px;
}

.voice-index {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.voice-index-element {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.voice-index-element .text {
    flex-grow: 1;
}

.voice-index-element .key {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}

.voice-index-element .length {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
}

.voice-index-element .play {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    border: black 1px solid;
}